@import "./src/visuals/styles/style-util";

.element__external-link {

  .component__mighty-button {
    margin: 0 0 0 5px;
  }

  &.small {
    .component__mighty-button {
      font-size: 12px !important;
    }
  }
}
