@import "src/visuals/styles/style-util";

.p-component-overlay {
  background-color: transparent !important;
}

.section__master-content {
  @include vertical-layout;

  .p-dataview {
    @include vertical-layout;
  }

  .p-dataview-content {
    @include vertical-layout;
    @include scrollable;
  }

  .p-dataview-header {
    padding: 0.5em 0.75em;

    .section__table-header {
      margin-left: -10px;

      .p-fieldset {
        border: 1px $dialog_frame solid;

        .p-fieldset-toggler {
          color: $primary;
          font-size: 22px;
        }
      }

      .section__table-header-actions {
        @include row;
        justify-content: left;

        .breakpoint {
          flex: 1 0 auto;
        }
        .element__table-header-action {
          margin-top: 7px;
          margin-bottom: 7px;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }

        .p-dropdown {
          min-width: 150px;
        }
        .spacer {
          flex-grow: 3;
        }
      }
    }
  }

  .p-paginator {
    .p-paginator-current {
      padding-left: 5px;
    }

    .p-paginator-pages {
      .p-paginator-element {
        padding: 5px;
      }

      .p-highlight {
        font-weight: bold;
      }
    }
  }

  .element__placeholder-no-data {
    @include label;
    margin-top: 10px;
  }

  .element__reload-entries {
    color: $icon-btn_icon;
    font-size: $icon_font_size;
    cursor: pointer;
  }

  .component__spinner {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
