@import "src/visuals/styles/style-util";

.component__detail-card {
  @include vertical-layout;
  flex: 1;
  min-width: 300px;
  margin-bottom: 20px;

  .section__header {
    @include row-space-between;
    margin-left: 0;
    min-height: 38px;

    .element__caption {
      @include caption;
      margin-left: 20px;
      flex: 1;
    }
  }

  .section__card-frame {
    @include card;
    flex: 1;
    min-height: 0;
    padding: 20px;
  }

  .section__content {
    @include vertical-layout;
    margin-bottom: 20px;
  }
}
