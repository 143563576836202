@import "src/visuals/styles/style-util";

.component__filter-bar-dialog {


  .component__detail-card {
    .section__header {
      display: none;
    }

    .section__content {
      padding: 0;
      margin: 0;
    }
  }

  .section__actions {
    display: flex;
    justify-content: space-between;

    div {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > * {
        margin-left: 25px;
      }
    }
  }

  .element__caption {
    @include caption;
    margin-left: 20px;
  }
}
