@import "style-util";

textarea {
  font-family: inherit;
  font-size: inherit;
}

.p-inputtext {
  padding: 10px 10px 10px 5px;
  display: block;
  border: none;
  border-bottom: 1px solid $input_frame;
  background: $input_background;

  &:focus, &.p-focus {
    border-bottom: 1px solid $input_frame__active;
  }
}

.p-multiselect {
  background: $input_background;
  border: none;
  border-bottom: 1px solid $input_frame;
  padding: 5px 5px 10px 5px;

  &:focus, &.p-focus {
    border-bottom: 1px solid $input_frame__active;
  }

  .p-multiselect-trigger {
    color: $dropdown-toggle__icon;
  }

  .p-placeholder {
    font-size: $label_font_size;
  }
}

.p-multiselect-panel {
  padding: 0;
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background: $main_background;

  .p-multiselect-filter-icon {
    color: $primary;
    font-size: $menu-font_size;
    right: 0;
  }
  .p-multiselect-header {
    padding: 5px;

    .p-multiselect-close-icon {
      display: none;
    }
  }

  .p-multiselect-items {

    .p-multiselect-item {
      padding: 5px;

      &:hover {
        background-color: $table-row_background__hover;
      }

      &:hover {
        .p-checkbox .p-checkbox-box {
          color: white;
          border-color: white;
        }
      }
    }
  }
}

.p-dropdown-panel {
  padding: 0;
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background: $main_background;

  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item {
      padding: 10px;

      &:hover {
        background-color: $table-row_background__hover;
      }
    }
  }
}

.p-dropdown {
  background: $input_background;
  border: none;
  border-bottom: 1px solid $input_frame;

  &:focus, &.p-focus {
    border-bottom: 1px solid $input_frame__active;
  }

  .p-inputtext {
    border: none !important;
  }

  .p-dropdown-trigger {
    color: $dropdown-toggle__icon;
    padding-right: 5px;
    z-index: 1000;
  }

  .p-placeholder {
    font-size: $label_font_size;
  }
}

.p-datepicker {
  background-color: white;

  tbody td span {
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 50px;
    text-align: center;

    &:hover:not(.p-disabled) {
      background-color: $table-row_background__hover;
    }
  }

  tbody td.p-datepicker-today span {
    border: 1px solid $input_frame;
  }

  .p-disabled {
    color: $input_text__disabled;
    background-color: inherit;
  }
}

.p-calendar {
  @include row;
  flex-direction: row-reverse;
  justify-content: flex-start;

  // Fix for different element height in calendar input fields
  height: 37px;

  > :not(.element__label) {
    height: 100%;
  }

  .p-datepicker-trigger {
    background-color: $input_background;
    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $input_frame;
    padding: 4px 4px 3px;
    color: $icon-btn_icon;
    font-size: $default_font_size;
    height: 100%;
  }

  &.p-inputwrapper-focus .p-button {
    border-bottom: 1px solid $input_frame__active;
  }
}

.p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  .p-checkbox-box {
    border: 1px solid $checkbox_frame;
    background-color: $checkbox_background;
    color: $checkbox_icon;
    width: 20px;
    height: 20px;
    text-align: center;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &:not(.p-disabled):hover {
      color: $checkbox_icon__hover;
      border-color: $checkbox_frame__hover;
      background-color: $checkbox_background__hover;
    }

    .p-checkbox-icon {
      overflow: hidden;
      position: relative;
      font-size: 18px;
    }
  }

  .p-checkbox-label {
    margin: 0 0 0 0.5em;
  }
}
