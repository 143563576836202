@import "src/visuals/styles/style-util";

.component__mighty-button {
  margin-bottom: 5px;

  .p-button-icon-left {
    font-size: 22px;
    margin-left: -6px;
    margin-right: 4px;
  }

  &.icon-only {
    cursor: pointer;
    font-size: 15pt !important;
    padding: 3px;
    margin: 2px 2px 0;
    color: $icon-btn_icon;
    border-bottom: 2px solid transparent;

    .icon-label {
      @include label;
      color: $icon-btn_icon;
      padding-left: 5px;
      font-size: 15pt;
    }

    &:hover:not(.disabled) {
      border-bottom: 2px solid $icon-btn_frame__hover;
    }

    &.disabled {
      color: $icon-btn_icon__disabled;
      cursor: default;
    }

    &.btn-positive {
      color: $positive-btn_background;
    }

    &.btn-negative {
      color: $negative-btn_background;
    }
  }
}

.p-dialog-wrapper {
  margin-left: 0 !important;

  .p-dialog {
  }

  .p-dialog-enter-done {
  }
}
