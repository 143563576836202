/*
  Utility SCSS (variables & mix-ins) that can be imported into every component style.
 */
@import "theme";

@mixin row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin row-centered-content {
  @include row;
  justify-content: space-around;
}

@mixin centered-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin row-space-between {
  @include row;
  margin-left: -20px;
  > * {
    margin-left: 20px;
  }
}

@mixin row-layout {
  @include row-space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

@mixin row-separated-content {
  @include row;
  justify-content: space-between;
  margin-left: -20px;
  > * {
    margin-left: 20px;
  }
}

@mixin vertical-layout {
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

@mixin column-space-between {
  @include vertical-layout;
  margin-top: -20px;
  > * {
    margin-top: 20px;
  }
}

@mixin two-column-summary {
  @include row;
  max-width: 700px;
  flex-wrap: wrap;
  align-items: flex-start;

  > :not(:last-child) {
    margin-bottom: 5px;
  }

  > *:nth-child(2n) {
    width: 60%;
  }
  > *:nth-child(2n+1) {
    width: 40%;
  }
}

@mixin card {
  background-color: $card_background;
  box-shadow: 0 3px 5px #0000001A;
  display: flex;
  flex-direction: column;
  min-height: auto;
}

@mixin coverimg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  height: 0;
  padding-top: 25%;
  padding-bottom: 26%;

  img {
    width: 100%;
    object-fit: cover;
  }
}

@mixin offset-scrollbar {
  margin-right: -10px;
}

@mixin scrollable {
  min-height: 0;
  overflow-y: auto;
  padding-right: 10px;

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: $scrollbar_foreground $scrollbar_background;

  // Chrome/Webkit
  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar_background;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar_foreground;
    border-radius: 10px;
    background-clip: padding-box;
    border: 3px solid $scrollbar_background;
  }
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  -webkit-tap-highlight-color: transparent;
}
