@import "src/visuals/styles/style-util";

.component__footer {
  @include row;
  align-items: baseline;
  border-top: 5px solid $primary;
  padding: 20px 50px 30px 140px;
  background-color: $side_background;
  color: $primary;

  > * {
    display: block;
  }

  .element__navlink {
    padding-top: 0;
  }

  .section__left {
    display: flex;
    flex: 1;
  }

  .element__navlink.with-logo {
    margin-right: 0;

    div {
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
    }
  }

  @media only screen and (max-width: $desktop) {
    padding-left: 5px;
  }

  @media only screen and (max-width: $laptop) {
    padding-bottom: 10px;
    padding-top: 5px;
    padding-right: 10px;

    .element__navlink {
      font-size: $default_font_size;
    }
  }

  @media only screen and (max-width: $mobile_landscape) {
    .element__navlink.with-logo svg {
      max-width: 100px;
    }
  }

  @media only screen and (max-width: $tablet-breakpoint) {
    justify-content: space-between;
    > * {
      margin-right: 0;
    }
  }
}
