@import "src/visuals/styles/style-util";

.component__spinner {
  width: 100%;
  display: flex;
  justify-content: center;

  .section__balls {
    display: flex;
    flex-direction: row;

    .ball-loader-ball {
      flex: 1;
      margin-bottom: 3px;
      margin-right: 3px;
      will-change: transform;
      border-radius: 50%;
      background-color: $spinner_icon;
      animation: grow 0.8s ease-in-out infinite alternate;

      &.ball1{
      }
      &.ball2{
        animation-delay: 0.33s;
      }
      &.ball3{
        animation-delay: 0.66s;
      }
    }
  }

  @keyframes grow {
    to {
      transform: translateX(-50%) scale(0.2);
    }
  }
}
