@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';
@import "src/visuals/fonts/fonts";
@import "layout";
@import "overlays";
@import "buttons-links";
@import "inputs";
@import "transitions";
@import "style-util";

body {
  overflow-y: hidden;
  @include default-text;
}

svg, svg * {
  font-family: 'Roboto', sans-serif !important;
}

* {
  outline: none;
}

i {
  @include noselect;
}

.element__description {
  white-space: break-spaces;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  &:hover {
    overflow-y: auto;
  }
}


.p-picklist {
  .p-picklist-caption {
    @include subcaption;
    margin-bottom: 0;
  }

  .p-picklist-list {
    background-color: $main_background;
    padding: 10px;

    .p-picklist-item span {
      padding: 5px;
      border-bottom: 1px solid $table-row_frame;
      display: block;
    }
  }
  .p-button {
    background-color: transparent;
    border: 1px solid $tertiary-btn_frame;
  }
}

.element__label {
  @include label;
}


