@import "src/visuals/styles/style-util";

.component__filter-bar {
  .element__filter-box {
    @include row-separated-content;
    margin-bottom: 10px;

    .element__filter-box-remover {
      margin-top: 10px;
    }

    .section__filter-box {
      padding-bottom: 10px;
      flex: 1;

      .section__filter-row {
        @include row;

        flex-wrap: wrap;

        > :not(.element__label) {
          flex: 1;
        }
      }
    }
  }

  .section__date-filter, .section__range-filter {
    .element__label {
      margin-left: 5px;
      margin-right: 5px;
    }

    .p-inputwrapper,
    .p-inputtext {
      min-width: 60px;
    }
  }
}
