@import "src/visuals/styles/style-util";

.component__data-view-item {
  @include card;
  padding: 7px 10px 5px 15px;
  min-width: 200px;
  margin-bottom: 15px;
  position: relative;

  .section__summary {
    margin-bottom: 0 !important;
  }

  &.selected {
    background-color: $table-row_background__selected;
    box-shadow: none;

    &::before {
      display: block;
      position: absolute;
      left: 0;
      content: ' ';
      background: transparent url('./caret-right.svg') no-repeat padding-box;
      background-size: 8px 15px;
      height: 15px;
      width: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .section__header {
    @include row-separated-content;
    align-items: flex-start;
  }

  .element__title {
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .element__subtitle {
    color: $label_text;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
