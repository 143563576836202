@import "src/visuals/styles/style-util";

.component__master-detail {
  @include vertical-layout;

  .section__detail {
    @include vertical-layout;
    flex: 1;
    position: relative;

    .section__detail-content {
      @include scrollable;
    }
  }

  .p-splitter {
    width: 100%;
  }

  .p-splitter-panel {
    @include vertical-layout;
  }

  .p-splitter-gutter-handle {
    background-color: $primary-hover;
  }

  .section__master {
    @include vertical-layout;
    position: relative;
    flex: 1;

    &.selectable {
      .component__data-view-item {
        &:not(.selected):hover {
          cursor: pointer;
          background-color: $table-row_background__hover !important;
        }
      }
    }
  }

  .view__single {
    .view-switch-enter {
      opacity: 0;
    }

    .view-switch-enter-active {
      opacity: 1;
      transition: all 300ms;
    }

    .view-switch-exit {
      opacity: 1;
      transform: translateX(0);
    }

    .view-switch-exit-active {
      opacity: 0;
      transition: all 300ms;
    }

    // hack to hide main app menu on detail view
    .section__detail {
      margin-top: -48px;
      margin-left: -5px;
      padding-left: 5px;
      margin-right: -5px;
      padding-right: 5px;
      height: calc(100% + 90px);
      background: $main_background;
    }
  }

  .view__shared {
    @include row-layout;
    flex-wrap: nowrap;
  }
}
