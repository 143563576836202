/*
  Global color variables for the theming of the application.
  Variable naming scheme: element-type_asset__state
  where asset is 'text', 'background', 'icon', 'frame'..
  state is 'hover', 'active', 'disabled', 'valid', 'invalid', 'selected'..
 */

/* Color definitions */
$primary: #01a747;
$primary-hover: #21E6D211;
$secondary: #01a747;

$main_background: #FFFFFF;
$side_background: #FFFFFF;
$main_text: #000000;
$side_text: #808080;
$side_text__disabled: #626262;
$navbar_background: #01a747;

/* Texts & Links */
$label_text: #717171;
$notification_text: #FFFFFF;
$notification_background: $primary;
$error_text: $main_text;
$error_frame: #E65621;

$navbar-item_frame: $primary;
$navbar-item_frame__hover: #FFFFFF;
$navbar-item_background__hover: $primary;
$navbar-item_text: #FFFFFF;
$navbar-item_text__hover: #FFFFFF;
$navbar-item_text__disabled: #BFBFBF;
$navbar-item_frame__disabled: transparent;
$navbar-item_frame__active: #FFFFFF;
$side_secondary-text: #9C9B9B;

$textlink_frame: $primary;
$textlink_background__hover: rgba($primary, .2);

/* Buttons */
$btn_text: #000000;
$positive-btn_background: $primary;
$negative-btn_background: #E65621;

$primary-btn_frame: $primary;
$primary-btn_background: rgba($primary, .5);
$primary-btn_text__hover: $main_text;
$primary-btn_background__hover: rgba($primary, .8);
$primary-btn_background__disabled: #F5F5F5;
$primary-btn_text__disabled: #BFBFBF;
$primary-btn_frame__disabled: #BFBFBF;

$secondary-btn_text: $main_text;
$secondary-btn_frame: #BFBFBF;
$secondary-btn_background: #BFBFBF;
$secondary-btn_text__hover: #FFFFFF;
$secondary-btn_background__hover: #BFBFBF;
$secondary-btn_text__disabled: #BFBFBF;
$secondary-btn_frame__disabled: #BFBFBF;
$secondary-btn_background__disabled: #F5F5F5;

$tertiary-btn_icon: $primary;
$tertiary-btn_frame: $primary;
$tertiary-btn_background: transparent;
$tertiary-btn_text__hover: $main_text;
$tertiary-btn_icon__hover: $primary;
$tertiary-btn_background__hover: rgba($primary, .1);
$tertiary-btn_frame__disabled: #BFBFBF;
$tertiary-btn_text__disabled: #BFBFBF;
$tertiary-btn_icon__disabled: #BFBFBF;
$tertiary-btn_background__disabled: transparent;

$warning-btn_frame: #E65621;
$warning-btn_background: transparent;
$warning-btn_background__hover: $warning-btn_frame;

/* Icons */
$icon-btn_icon: $primary;
$icon-btn_icon__hover: $primary;
$icon-btn_icon__disabled: #BFBFBF;
$icon-btn_frame__hover: $primary;
$side-icon-btn_icon: $primary;
$side-icon-btn_icon__hover: #FFFFFF;

/* Input fields */
$input_text__disabled: #c9c9c9;
$input_frame: #000000;
$input_frame__active: $primary;
$checkbox_frame: $primary;
$input_background: #F0F0F0;
$checkbox_background: transparent;
$input-indicator__valid: #008800;
$input-indicator__invalid: #E65621;
$label__invalid: #E65621;
$dropdown-toggle__icon: $primary;
$checkbox_icon: $primary;
$checkbox_icon__hover: white;
$checkbox_background__hover: $primary;
$checkbox_frame__hover: $primary;

/* Forms & Tables */
$card_background: #F9F9F9;
$form_background: #F9F9F9;
$table-row_frame: #000000;
$table-row_background: transparent;
$table-row_background__hover: rgba($primary, .2);
$table-row_background__selected: #F9F9F9;
$document-row_frame: #00000033;
$document-row_icon__hover: #000000;
$document-row_background__hover: $primary;

/* Menu, Sidebar, Dialogs */
$sidebar-item_icon: $primary;
$sidebar-item_text__hover: $primary;
$sidebar-separator_frame: $primary;
$menu-item_icon: $primary;
$dialog_background: #FFFFFF;
$dialog_frame: #BFBFBF;
$dialog-title_icon: #E65621;
$dialog-overlay_background: rgba(0,0,0,.1);
$toast_icon__error: black;
$toast_background__error: #F4DAD1;
$toast_icon__success: $primary;
$toast_background__success: white;
$scrollbar_foreground: #BFBFBF;
$scrollbar_background: transparent;

/* Font sizes */
$label_font_size: 12px;
$default_font_size: 14px;
$menu_font_size: 18px;
$interactive_font_size: 20px;
$caption_font_size: 24px;
$navitem_font_size: 18px;
$icon_font_size: 24px;

$spinner_icon: $primary;

$mobile_landscape: 480px;
$tablet-breakpoint: 768px;
$tablet_landscape-breakpoint: 992px;
$laptop: 1200px;
$desktop: 1680px;

@mixin label {
  color: $label_text;
  font-family: 'Roboto', sans-serif;
  font-size: $label_font_size;
}

@mixin caption {
  color: $main_text;
  font-family: 'Roboto', sans-serif;
  font-size: $caption_font_size;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin subcaption {
  color: $main_text;
  font-family: 'Roboto', sans-serif;
  font-size: $default_font_size;
  margin-bottom: 15px;
  display: block;
  min-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin default-text {
  font-family: 'Roboto', sans-serif;
  font-size: $default_font_size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
