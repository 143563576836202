@import "style-util";

html, body, #root {
  height: 100vh;
  margin: 0;
  @include vertical-layout;
  align-items: stretch;
}

.element__placeholder-loading {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  @include vertical-layout;
  justify-content: center;
  align-items: center;

  span {
    @include caption;
    display: block;
  }

  .pi {
    font-size: 48px;
  }
}

.section__middle-section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  min-height: 0;
  flex: 1;

  .section__page-content {
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;

    padding: 10px 30px 10px 140px;
    @media only screen and (max-width: $desktop) {
      padding: 5px 5px 5px 5px;
    }

    flex: 1;
    display: flex;

    .app {
      @include vertical-layout;
      flex: 1;

      > .view {
        @include vertical-layout;
        flex: 1;
      }
    }

    .breakpoint {
      display: flex;
      flex: 1;
      min-height: 0;
    }
  }
}

.section__headline {
  margin-bottom: 20px;
  display: block;
}

.section__headline-bottom {
  margin-top: 20px;
  display: block;
}

.element__page-title {
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
}

.element__subcaption:first-of-type {
  margin-top: 0;
}

.element__title-component {
  @include row;
  padding-left: 10px;
}

.element__navigation {
  @include row;
  align-items: flex-start;
  margin-bottom: 10px;
}

.element__subcaption {
  @include subcaption;
  @include row;
}

.element__submit-error {
  color: $error_text;
  display: block;
  border: 1px solid $error_frame;
  padding: 8px;
}

.user-mask-view {
  @include vertical-layout;
  @include scrollable;

  padding-left: 285px;
  max-width: 600px;
  @media only screen and (max-width: $desktop) {
    padding-left: 215px;
  }
  @media only screen and (max-width: $laptop) {
    padding-left: 0;
  }

  .element__page-title {
    margin-bottom: 20px;
  }

  .component__abstract-form {
    max-width: 400px;
    padding: 0;

    form {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }

    .section__formdata {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .section__header {
      display: none;
    }
  }

  .section__quick-links {
    > * {
      font-family: 'Roboto', sans-serif;
      display: block;
    }

    > span:first-of-type {
      margin-bottom: 10px;
    }
  }
}


.section__summary {
  position: relative;
  @include two-column-summary;

  > * {
    margin-bottom: 10px;
    word-wrap: break-word;
  }

  > *:nth-child(2n+1) {
    @include label;
  }

  .component__location-map {
    width: 100%;
    max-height: 200px;
  }

  margin-bottom: 30px;
}

.section__actions {
  @include row-space-between;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
