@import "src/visuals/styles/style-util";

.p-slidemenu {
  background-color: $side_background;
  font-size: $menu_font_size;
  margin-top: 10px;
  max-width: 200px;
  min-height: 280px;

  .p-slidemenu-wrapper {
    min-height: 280px;
  }

  .p-slidemenu-rootlist {
    position: relative;
  }

  * {
    background-color: $side_background;
    color: $side_text;

    .pi {
      color: $sidebar-item_icon;
      font-size: 20px;
    }
  }

  .p-menuitem-icon {
    margin-right: 0.25em;
  }

  .p-menuitem-link {
    padding: 0.25em;
  }

  .p-menuitem {
    padding: 5px;

    &.p-disabled .p-menuitem-text {
      color: $navbar-item_text__disabled;
    }
  }

  .p-menuitem-link:hover, .p-slidemenu-backward:hover {
    * { color: $sidebar-item_text__hover; }
  }

  &#menu__language {
    max-width: 100px;
    min-height: 0;
    .p-slidemenu-rootlist {
      max-width: 100px;
    }

    .p-slidemenu-content {
      overflow-x: unset;
      overflow-y: unset;
    }

    .p-slidemenu-wrapper {
      min-height: 0;
      height: unset !important;
    }
  }
}

