@import "style-util";

.p-component-overlay {
  background-color: $dialog-overlay_background;
}

.p-dialog-mask {
  z-index: 1001 !important; // In order to be in front of leaflet buttons which have z-index 1000

  .p-dialog {
    z-index: 1001;
    border: 1px solid $dialog_frame;
    padding: 0.5em 0.75em;
    max-width: 600px;
    min-width: 315px;
    width: 400px;
    background-color: $dialog_background;

    .p-dialog-header {
      background-color: $dialog_background;
      font-weight: 700;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      .p-dialog-header-icons span, .p-dialog-header-icons i {
        color: $dialog-title_icon;
        font-size: $icon_font_size;
        margin-right: 15px;
      }
    }
  }
}

.p-toast {
  opacity: 1;
  z-index: 1011;
  height: 0;
  width: initial;

  &.p-toast-enter {
    opacity: 0.1;
    transform: translateY(20%);
  }

  &.p-toast-enter.p-toast-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms linear;
  }

  &.p-toast-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.p-toast-exit.p-toast-exit-active {
    opacity: 0.2;
    max-height: 500px;
    transform: translateX(40%);
    transition: all 500ms ease-in;
  }

  &.p-toast-top-right {
    top: 200px;
    right: 20px;
    @media only screen and (max-width: $mobile_landscape) {
      top: unset;
      bottom: 100px;
      right: 5px;
      left: 5px;
    }
  }

  .p-toast-message {
    max-width: 400px;
    box-shadow: 0 3px 6px #00000029;
    border: 0.5px solid #BFBFBF40;
    margin-bottom: 10px;

    &.p-toast-message-error {
      background-color: $toast_background__error;

      .p-toast-icon-close-icon {
        color: $toast-icon__error;
      }
    }

    &.p-toast-message-success {
      background-color: $toast_background__success;

      .p-toast-icon-close-icon {
        color: $toast-icon__success;
      }
    }

    div {
      white-space: break-spaces;
    }

    .p-toast-message-content {

      .p-toast-message-icon {
        display: none;
      }

      .p-toast-message-text {
        padding: 10px;

        .p-toast-summary {
          font-weight: 700;
          padding-bottom: 10px;
        }

        .p-toast-detail {
          margin-top: 10px;
          margin-left: 17px;
          margin-right: 50px;
        }
      }

      .p-toast-icon-close {
        color: $icon-btn_icon;
        font-size: $icon_font_size;
      }
    }
  }
}

.p-input-overlay {
  background-color: $dialog_background;
  border: 1px solid #c8c8c8;
  padding: 1em;
}

.p-overlaypanel {
  border: 1px solid $dialog_frame;
  background-color: $dialog_background;

  :hover {
    background-color: $dialog_background;
  }
}
