@import "src/visuals/styles/style-util";

.component__navbar {
  .navbar__background {
    background-color: $navbar_background;
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 50px;
    display: block;
  }
  .breakpoint {
    @include row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 25px;

    @media only screen and (max-width: $laptop) {
      margin-bottom: 5px;
    }
  }
  .section__active-item {
    @include row;
    position: relative;
    align-items: flex-end;
  }

  .element__navitem {
    margin-bottom: 5px;
    color: $navbar-item_text;
    white-space: pre;
    display: inline-block;
    margin-right: 20px;
    font-size: $navitem_font_size;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 13px;
    border-bottom: 2px solid $navbar-item_frame;
    position: relative;
    font-weight: bold;

    &.active {
      cursor: default;
      border-bottom: 2px solid $navbar-item_frame__active;
      position: relative;
    }

    &.disabled:not(.active) {
      cursor: default;
      color: $navbar-item_text__disabled;
      border-bottom: $navbar-item_frame__disabled;
    }

    &:not(.active):not(.disabled):hover,
    &:not(.disabled):focus {
      background: $navbar-item_background__hover;
      color: $navbar-item_text__hover;
    }
  }

  .element__expander {
    display: inline-block;
    margin-left: -20px;
    margin-bottom: 5px;
    .component__mighty-button {
      color: $navbar-item_text;
    }
  }

  .p-menu {
    z-index: 9999;
    top: 100% !important;
    left: 0 !important;
    min-width: 250px;
    box-shadow: 0 3px 6px #00000029;
    background: $main_background;
    padding: 0;
    width: 100%;

    .p-menuitem {
      .p-menuitem-link {
        font-size: 16px;
        margin: 0;
        padding: 16px;
        border-bottom: 1px solid #0000000D;
      }

      &.p-disabled {
        color: $side_text__disabled;
      }
      &:not(.p-disabled):hover {
        background: $navbar-item_background__hover;
        color: white;
      }
    }
  }
}
