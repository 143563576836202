
.transition__appear-enter {
  opacity: 0;
}

.transition__appear-enter-active {
  opacity: 1;
  transition: all 200ms ease;
}

.transition__appear-exit {
  opacity: 1;
}

.transition__appear-exit-active {
  opacity: 0;
  transition: all 200ms ease;
}
