@import "style-util";

a {
  text-decoration: none;
  color: inherit;
}

.pi-rotate-90 {
  transform: rotate(90deg);
}

.p-button {
  color: $btn_text;
  padding: 7px 17px 7px 20px;
  font-size: $default_font_size;
  margin-right: 0;

  &.p-button-primary {
    background-color: $primary-btn_background;
    border: 1px solid $primary-btn_frame;

    &:hover {
      color: $primary-btn_text__hover;
      background-color: $primary-btn_background__hover;
    }

    &:disabled {
      background-color: $primary-btn_background__disabled;
      color: $primary-btn_text__disabled;
      border: 1px solid $primary-btn_frame__disabled;
      font-style: italic;
    }
  }

  &.p-button-secondary {
    background-color: $secondary-btn_background;
    color: $secondary-btn_text;
    border: 1px solid $secondary-btn_frame;

    &:hover {
      color: $secondary-btn_text__hover;
      background-color: $secondary-btn_background__hover;
    }

    &:disabled {
      background-color: $secondary-btn_background__disabled;
      color: $secondary-btn_text__disabled;
      border: 1px solid $secondary-btn_frame__disabled;
      font-style: italic;
    }
  }

  &.p-button-tertiary {
    background-color: $tertiary-btn_background;
    border: 1px solid $tertiary-btn_frame;
    color: $main_text;
    font-size: $default_font_size;

    .pi { color: $tertiary-btn_icon; }

    &:hover {
      background-color: $tertiary-btn_background__hover;
      color: $tertiary-btn_text__hover;
      .pi { color: $tertiary-btn_icon__hover; }
    }

    &:disabled {
      background-color: $tertiary-btn_background__disabled;
      border: 1px solid $tertiary-btn_frame__disabled;
      color: $tertiary-btn_text__disabled;
      .pi {
        color: $tertiary-btn_icon__disabled;
      }
    }
  }

  &.p-button-warning {
    background-color: $warning-btn_background;
    border: 1px solid $warning-btn_frame;
    color: $main_text;
    cursor: pointer;
    font-size: $default_font_size;

    &:hover {
      background-color: $warning-btn_background__hover;
    }
  }

  &.p-button-icon-text {
    @include row;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    color: $main_text;
    text-transform: none;
    border-bottom: 1px solid transparent;
    padding: 0 0 3px;
    margin: 0 0 -3px;
    font-weight: normal;

    &.p-button-text-icon-left .p-button-text {
      margin-left: 3px;
    }

    .pi {
      color: $icon-btn_icon;
      font-size: $menu_font_size;
      position: relative;
      padding: 0;
      margin: 0 5px 0 0;
      left: 0;
    }

    &:hover:not(:disabled) {
      border-bottom: 1px solid $icon_btn_frame__hover;
    }

    &:disabled {
      background-color: $tertiary-btn_background__disabled;
      color: $tertiary-btn_text__disabled;
      .pi {
        color: $tertiary-btn_icon__disabled;
      }
    }
  }

  .p-button-text {
    padding: 0;
  }
  &.p-button-text-icon-left .p-button-text {
    margin-left: 7px;
  }
}

.element__text-link {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  border-bottom: 2px solid $textlink_frame;
  padding-top: 2px;
  display: inline-block;

  &:hover {
    background: $textlink_background__hover;
  }
}

.email {
  font-weight: bold;
}
.email-link {
  color: #28B1FF;
}
