@import "src/visuals/styles/style-util";

.component__header {
  background-color: $side_background;
  color: $side_text;
  padding: 10px 15px 10px 25px;

  .section__inner {
    @include row-separated-content;

    .element__menu-toggle {
      display: none;
    }

    .section__logo {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      a {
        width: 100%;
        max-width: 420px;
      }

      @media only screen and (max-width: $laptop) {
        margin-right: 30px;
        justify-content: flex-start;

        a {
          max-width: 300px;
        }
      }
      @media only screen and (max-width: $tablet-breakpoint) {
        a {
          max-width: 200px;
        }
      }
    }

    .section__user {
      cursor: pointer;

      .section__menu-toggle {
        @include row;
        font-size: $interactive_font_size;

        i {
          color: $menu-item_icon;
          font-size: $icon_font_size;
          padding: 2px;
          margin-right: 5px;
          margin-left: 5px;
        }

        i:first-child {
          border: 1px solid $menu-item_icon;
          border-radius: 20px;
          margin-right: 12px;
        }
      }

      .section__active-role {
        font-size: $default_font_size;
        color: $side_secondary-text;

        span {
          margin-left: 47px;
        }
      }
    }
  }

  .section__title {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    font-size: $caption_font_size;
    color: $main_text;
    font-weight: bold;

    @media only screen and (max-width: $laptop) {
      font-size: $interactive_font_size;
    }
    @media only screen and (max-width: $tablet-breakpoint) {
      font-size: $menu_font_size;
    }
    @media only screen and (max-width: $mobile_landscape) {
      font-size: $default_font_size;
    }
  }

  .section__buttons {
    @include row-space-between;
  }

  .section__language {
    margin-left: 20px;
    margin-right: 6%;
    cursor: pointer;
    @include vertical-layout;
    align-items: center;

    i {
      opacity: 0.8;
      color: $primary;
      font-size: 18pt;
      z-index: 0;
    }

    span {
      @include noselect;
      margin-top: -8px;
      z-index: 1;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      color: $side_text;
      font-size: 10pt;
      border-bottom: 1px solid transparent;
      margin-left: -15px;
    }

    &:hover span {
      border-bottom: 1px solid $primary;
    }
  }

  .element__navlink {
    display: inline-block;
  }

  .section__login {
    font-size: $interactive_font_size;
    padding-bottom: 5px;
    * {
      margin-right: 10px;
    }
  }

  .element__menu-toggle {
    color: $side-icon-btn_icon;
    font-size: $icon_font_size;
    cursor: pointer;

    &:hover {
      color: $side-icon-btn_icon__hover;
    }
  }
}

.element__navlink {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  padding-top: 25px;
  margin-right: 10px;

  > .element__underline {
    padding-bottom: 22px;
    width: 25px;
    margin-top: 5px;
  }

  &:hover .element__underline {
    padding-bottom: 20px;
    border-bottom: 2px solid $navbar-item_frame;
  }
}
