@import "src/visuals/styles/style-util";

.p-sidebar.component__sidebar {
  max-width: 100%;
  background-color: $side_background;
  color: $side_text;
  @media only screen and (max-width: $tablet-breakpoint) {
    padding-right: 0;
  }
  font-family: 'Roboto', sans-serif;
  font-size: $interactive_font_size;

  .pi {
    font-size: $icon_font_size;
  }
  .p-sidebar-content {
    padding: .5em 1em;
    max-width: 100%;
  }
  .section__logo {
    @include row-separated-content;
    padding: 20px 10px 10px;
    margin-bottom: 20px;
    @media only screen and (max-width: $laptop) {
      padding: 5px;
    }

    img {
      max-width: 171px;
    }

    .element__menu-toggle {
      padding: 5px;
      cursor: pointer;
      font-size: $icon_font_size;
      color: $side-icon-btn_icon;

      &:hover {
        color: $side-icon-btn_icon__hover;
      }
    }
  }

  .section__title,
  .section__login {
    @include vertical-layout;

    > * {
      .element__navlink {
        display: block;
      }

      margin-bottom: 20px;
      display: inline-block;
    }

    .element__login-separator {
      display: none;
    }
  }

  .section__menu {
    margin-left: -20px;
  }

  .section__user {
    span:last-of-type {
      font-size: $default_font_size;
      color: $side_secondary-text;
    }

    span:first-of-type {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: $side_text;
      display: block;
    }

    margin-bottom: 30px;
    @media only screen and (max-height: $tablet-breakpoint) {
      margin-bottom: 5px;
    }
  }

  .component__menu-section {
    color: $side_text;
    margin-top: 20px;

    > .section__head {
      margin-bottom: 10px;
      @media only screen and (max-height: $tablet-breakpoint) {
        margin-bottom: 0;
      }
    }

    .component__menu-entry {
      margin-top: 10px;
      margin-bottom: 0;
      padding-left: 15px;
      @media only screen and (max-height: $tablet-breakpoint) {
        margin-top: 2px;
      }
    }

    margin-bottom: 20px;
    @media only screen and (max-height: $tablet-breakpoint) {
      margin-bottom: 2px;
      margin-top: 2px;
    }
  }

  .pi {
    width: 25px;
  }

  .section__head {
    padding: 10px 10px 10px 20px;
    @media only screen and (max-height: $tablet-breakpoint) {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    span {
      display: block;
      margin-left: 15px;
    }

    @include row;
  }

  .component__menu-entry {
    color: $primary;
    cursor: pointer;
    margin-top: 30px;
    @media only screen and (max-height: $tablet-breakpoint) {
      margin-top: 5px;
    }

    &.p-disabled {
      color: $side_text__disabled;
      font-style: italic;
    }

    &:not(.p-disabled):hover {
      color: $navbar-item_frame__hover;
      background: $navbar-item_background__hover;
    }
  }

  .section__menu > .component__menu-entry:last-of-type {
    border-top: 1px solid #707070;
  }
}
